<template>
  <div
    v-bind:class="{
      'text-sm text-red-700 bg-red-100': alertMessage.type == 'fault',
      'text-green-700 bg-green-100': alertMessage.type == 'success',
      'text-blue-700 bg-blue-100': alertMessage.type == 'info',
      'text-yellow-700 bg-yellow-100': alertMessage.type == 'warning',
    }"
    class="flex p-4 mb-4 text-sm rounded-lg"
    role="alert"
  >
    <svg
      class="inline flex-shrink-0 mr-3 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <div>
      {{ alertMessage.msg }}
      <span v-if="alertMessage.link"
        ><br />Zkuste
        <a href="https://zaparkujlevne.cz" class="underline" target="_blank"
          >zkusit parkoviště zaparkujlevne.cz</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import { watch } from 'vue'
import { useGtm } from '@gtm-support/vue-gtm'
export default {
  props: {
    alertMessage: Object,
  },

  setup(props) {
    const gtm = useGtm()

    const triggerAlertEvent = () => {
      //report only errors and warnings
      if (props.alertMessage.type === 'warning' || props.alertMessage.type === 'fault') {
        gtm.trackEvent({
          event: 'alertSent',
          alertType: props.alertMessage.type,
          alertMsg: props.alertMessage.msg,
        })
      }
    }

    watch(
      () => props.alertMessage,
      () => {
        //console.log('Watch props.selected function called with args:', first, second)
        triggerAlertEvent()
      },
      { immediate: true }
    )

    return {
      triggerAlertEvent,
    }
  },
}
</script>

<style lang="scss" scoped></style>
