<template>
  <!-- Component Start -->
  <div
    className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 w-full max-w-screen-lg mx-auto px-5 mt-10 mb-10"
  >
    <div className="md:col-span-3 bg-white rounded-lg border border-gray-200 shadow-md p-5">
      <Alert v-if="alertMessage" :alert-message="alertMessage" class="mt-2" />
      <button
        @click.prevent="reset"
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
      >
        Další rezervace
      </button>

      <a
        v-if="parkingId == 1"
        href="https://topparking.cz/"
        type="button"
        class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >Zpět na web</a
      >
      <a
        v-if="parkingId == 2"
        href="https://zaparkujlevne.cz/"
        type="button"
        class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >Zpět na web</a
      >
      <a
        v-if="parkingId == 3"
        href="https://www.parkoviste-u-letiste.cz/"
        type="button"
        class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >Zpět na web</a
      >
    </div>
  </div>
  <!-- Component End  -->
</template>

<script>
import Alert from './Alert.vue'
import { useGtm } from '@gtm-support/vue-gtm'
import { onMounted } from 'vue'

export default {
  name: 'DateForm',
  components: {
    Alert,
  },
  props: {
    alertMessage: Object,
    parkingId: Number,
  },

  setup() {
    const gtm = useGtm()

    const reset = () => {
      window.eventBus.emit('resetAll', {})
    }

    const triggerConversionEvent = () => {
      gtm.trackEvent({
        event: 'reservationSent',
      })
    }

    onMounted(() => {
      triggerConversionEvent()
    })

    return {
      reset,
      triggerConversionEvent,
    }
  },
}
</script>
