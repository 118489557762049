<template>
  <!-- Component Start -->
  <form
    @submit.prevent="submitForm"
    class="grid md:grid-cols-3 gap-4 sm:gap-8 w-full max-w-screen-lg mx-auto px-5 mb-20"
  >
    <div class="md:col-span-2">
      <div v-if="dateTimeStartVariants && dateTimeEndVariant" class="mb-5">
        <h2 class="text-xl font-medium">Termín příjezdu a odjezdu</h2>
        <Alert
          v-if="enabledVariants != 0"
          :alert-message="{
            type: 'info',
            msg: 'Prosím, zvolte finální čas příjezdu dle volných kapacit v transferu na letiště.',
          }"
          class="mt-2"
        />
        <Alert
          v-else
          :alert-message="{
            type: 'fault',
            msg: 'Je nám líto, ale nemáme kapacitu pro transfer zadaného množství osob.',
          }"
          class="mt-2"
        />
        <div
          class="bg-white rounded-lg border border-gray-200 shadow-md mt-2 px-5 sm:px-8 py-4 pt-5"
        >
          <p class="mt-0 mb-5">
            <strong>Datum a čas příjezdu:</strong>
          </p>
          <div
            v-for="(dateTimeStartVariant, index) in dateTimeStartVariants"
            :key="index"
            class="flex items-center mb-2 text-sm rounded-lg"
            :class="[
              dateTimeStartVariant.disabled === true
                ? 'bg-gray-100 border-gray-300 text-gray-400'
                : 'bg-green-100 border-green-200 hover:bg-green-300 hover:text-white',
              dateTimeStartVariant.dateTimeStart == dtStart ? 'bg-green-300 text-white' : '',
            ]"
          >
            <input
              :id="'date-time-start-variant-' + index"
              type="radio"
              name="date-time-start"
              v-model="dtStart"
              :value="dateTimeStartVariant.dateTimeStart"
              class="w-4 h-4 ml-3 focus:ring-2 focus:ring-blue-300"
              :class="
                dateTimeStartVariant.disabled === true ? 'border-gray-200' : 'border-gray-300'
              "
              required
              :disabled="dateTimeStartVariant.disabled === true"
            />
            <label
              :for="'date-time-start-variant-' + index"
              class="block px-2 text-md font-medium w-full h-full py-4"
            >
              {{ dateTimeStartVariant.dateStart }}
              <strong>{{ dateTimeStartVariant.timeStart }}</strong>
              <span class="opacity-70 text-sm block sm:inline">
                (Míst pro transfer:
                {{ dateTimeStartVariant.tranferSpaces }} )
              </span>
              <a
                href="https://zaparkujlevne.cz"
                class="text-gray-400 ml-2"
                target="_blank"
                v-if="dateTimeStartVariant.disabled"
                >Zkusit <span class="underline">ZAPARKUJLEVNE.CZ</span></a
              >
            </label>
          </div>

          <p class="mt-8 mb-4">
            <strong>Datum a čas odjezdu:</strong> {{ dateTimeEndVariant.dateEnd }}
            {{ dateTimeEndVariant.timeEnd }}
          </p>
          <input type="hidden" v-model="dtEnd" />
          <button
            @click.prevent="reset"
            type="button"
            class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            <div>
              <svg
                class="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                ></path>
              </svg>
            </div>
            Zpět
          </button>
        </div>
      </div>

      <div v-if="dateStartSelected" ref="personalDataElement">
        <h2 class="text-xl font-medium">Kontaktní údaje</h2>
        <Alert v-if="alertMessage" :alert-message="alertMessage" class="mt-2" />
        <div class="bg-white rounded-lg border border-gray-200 shadow-md mt-2">
          <div class="pt-5">
            <div class="sm:grid grid-cols-2 gap-4 px-5 sm:px-8 pb-8 mt-2">
              <div class="col-span-2">
                <Alert
                  v-if="lessThanFourHours === true"
                  :alert-message="{
                    type: 'warning',
                    msg: 'Prosím, zavolejte 30 minut před příjezdem na tel.: 776 817 814!',
                  }"
                  class="mt-2"
                />
                <label for="fullname" class="block mb-2 text-sm font-medium text-gray-900"
                  >Jméno a příjmení *</label
                >
                <input
                  type="text"
                  id="fullname"
                  v-model="fullname"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>

              <div class="mt-4 sm:mt-auto">
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900"
                  >E-mail *</label
                >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="emailConfirmation" class="block mb-2 text-sm font-medium text-gray-900"
                  >E-mail znovu *<span
                    v-if="emailConfirmation && email !== emailConfirmation"
                    style="color: red"
                  >
                    E-maily se musí shodovat
                  </span></label
                >
                <input
                  type="email"
                  id="emailConfirmation"
                  v-model="emailConfirmation"
                  :class="
                    emailConfirmation && email !== emailConfirmation
                      ? 'border-red-500'
                      : 'border-gray-300'
                  "
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="phone" class="block mb-2 text-sm font-medium text-gray-900"
                  >Telefon *</label
                >
                <input
                  type="text"
                  id="phone"
                  v-model="phone"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="brand" class="block mb-2 text-sm font-medium text-gray-900"
                  >Značka automobilu *</label
                >
                <input
                  type="text"
                  id="brand"
                  v-model="brand"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="spz" class="block mb-2 text-sm font-medium text-gray-900"
                  >Vaše SPZ *</label
                >
                <input
                  type="text"
                  id="spz"
                  v-model="spz"
                  minlength="7"
                  maxlength="8"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="airport" class="block mb-2 text-sm font-medium text-gray-900"
                  >Z jakého letiště přiletíte zpět do Prahy na letiště Václava Havla *</label
                >
                <input
                  type="text"
                  id="airport"
                  v-model="airport"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div class="mt-4 sm:mt-auto">
                <label for="flight-number" class="block mb-2 text-sm font-medium text-gray-900"
                  >Číslo zpátečního letu</label
                >
                <input
                  type="text"
                  id="flight-number"
                  v-model="flightNumber"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              <div class="col-span-2 mt-4 sm:mt-auto form-check hidden">
                <input
                  class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="baggage"
                  v-model="baggage"
                />
                <label class="form-check-label inline-block text-gray-800" for="baggage">
                  Objemná zavazadla (jízdní kolo, surf, lyže...) příplatek 300 Kč/kus *
                </label>
              </div>
              <div class="col-span-2 mt-4 sm:mt-auto">
                <label
                  for="note"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >Poznámka</label
                >
                <textarea
                  id="note"
                  v-model="note"
                  rows="4"
                  class="block p-2 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Vaše poznámka..."
                ></textarea>
              </div>
              <div class="col-span-2 mt-4 sm:mt-auto">
                <p>* doplňující info o objemných zavazadlech (druh, počet) uveďte do poznámky</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="sticky top-2">
        <h2 class="text-xl font-medium">Shrnutí rezervace</h2>
        <div class="bg-white rounded-lg border border-gray-200 shadow-md mt-2 py-6">
          <div class="px-5 sm:px-8 mt-4">
            <div class="flex items-end justify-between">
              <span class="text-sm font-semibold">Počet dní</span>
              <span class="text-sm text-gray-500 mb-px">{{ priceInfo.totalDays }}</span>
            </div>
            <div class="flex items-end justify-between">
              <span class="text-sm font-semibold">Ceník</span>
              <span v-if="priceInfo.priceList === 'winter'" class="text-sm text-gray-500 mb-px"
                >ZIMNÍ</span
              >
              <span v-if="priceInfo.priceList === 'summer'" class="text-sm text-gray-500 mb-px"
                >LETNÍ</span
              >
            </div>
          </div>
          <div class="px-5 sm:px-8 mt-4 border-t pt-4">
            <div class="flex items-end justify-between">
              <span class="font-semibold">Cena celkem (CZK)</span>
              <span class="font-semibold">{{ priceInfo.price }}</span>
            </div>
          </div>

          <div class="flex flex-col px-5 sm:px-8 pt-4">
            <label class="text-xs mt-3 mb-4 leading-5">
              <input type="checkbox" required class="form-checkbox" v-model="gdpr" />
              Souhlasím s
              <a
                href="https://topparking.cz/obchodni-podminky.pdf"
                target="_blank"
                class="text-blue-600 underline"
                >obchodními podmínkami a se zpracováním osobních údajů</a
              >.
            </label>
            <button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
              type="submit"
              :disabled="isLoading || enabledVariants == 0 || !dateStartSelected"
              :class="enabledVariants == 0 || !dateStartSelected ? 'opacity-20 ' : ''"
            >
              <span v-if="!isLoading">Rezervovat</span>
              <span v-else
                ><svg
                  role="status"
                  class="inline h-4 w-4 animate-spin mr-2 text-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  /></svg
                >Vyčkejte prosím...</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Component End  -->
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import moment from 'moment'
import Alert from './Alert.vue'

export default {
  name: 'ReservationForm',
  components: {
    Alert,
  },
  props: {
    dateTimeStartVariants: Array,
    dateTimeEndVariant: Object,
    priceInfo: Object,
    enabledVariants: Number,
    persons: Number,
    parkingId: Number,
  },

  setup(props) {
    const alertMessage = ref(null)
    const fullname = ref(null)
    const phone = ref(null)
    const email = ref(null)
    const emailConfirmation = ref(null)
    const brand = ref(null)
    const spz = ref(null)
    const airport = ref(null)
    const flightNumber = ref(null)
    const note = ref(null)
    const baggage = ref(false)
    const gdpr = ref(false)
    const dtStart = ref(null)
    const dtEnd = ref(props.dateTimeEndVariant.dateTimeEnd)
    const isLoading = ref(false)
    const lessThanFourHours = ref(false)

    const personalDataElement = ref(null)

    const scrollPageTo = (ref) => {
      if (ref.value !== null) ref.value.scrollIntoView({ behavior: 'smooth' })
    }

    const checkIfLessThanFourHours = () => {
      let a = moment(dtStart.value) //.format('YYYY-MM-DD HH:mm')
      let b = moment() //.format('YYYY-MM-DD HH:mm')
      let hoursDiff = a.diff(b, 'hour')
      if (hoursDiff <= 4) {
        lessThanFourHours.value = true
      } else {
        lessThanFourHours.value = false
      }
    }

    const submitForm = () => {
      if (email.value !== emailConfirmation.value) {
        alert('E-maily se musí shodovat!')
        return
      }
      isLoading.value = true
      axios({
        method: 'post',
        //url: 'http://topparkovani.devs/api/api.php',
        url: 'https://rezervace.topparking.cz/api/api.php',
        data: {
          method: 'processReservationForm',
          parkingId: props.parkingId,
          dateTimeStart: dtStart.value,
          dateTimeEnd: dtEnd.value,
          persons: props.persons,
          fullname: fullname.value,
          phone: phone.value,
          email: email.value,
          brand: brand.value,
          spz: spz.value,
          airport: airport.value,
          flightNumber: flightNumber.value,
          note: note.value,
          baggage: baggage.value,
        },
      }).then(function (response) {
        console.log(response.data)
        //status
        if (response.data.status) {
          //console.log(response.data)
          if (response.data.status === 'success') {
            gdpr.value = false
            window.eventBus.emit('successReservationForm', {
              alertMessage: response.data.alert,
            })
          }
          if (response.data.status === 'failure') {
            window.eventBus.emit('failureReservationForm', {
              alertMessage: response.data.alert,
            })
          }
        } else {
          //Alerts on this page
          if (response.data.alert) {
            alertMessage.value = response.data.alert
            isLoading.value = false
          } else {
            alertMessage.value = null
          }
          isLoading.value = false
        }
      })
    }
    const reset = () => {
      window.eventBus.emit('resetAll', {})
    }

    const dateStartSelected = computed(() => {
      if (dtStart.value !== null) {
        checkIfLessThanFourHours()
        scrollPageTo(personalDataElement)
        return true
      }
      return false
    })

    //precheck
    onMounted(() => {
      props.dateTimeStartVariants.filter(function (elem) {
        if (elem.checked === true) dtStart.value = elem.dateTimeStart
      })
    })

    return {
      alertMessage,
      fullname,
      phone,
      email,
      emailConfirmation,
      brand,
      spz,
      airport,
      flightNumber,
      note,
      baggage,
      gdpr,
      dtStart,
      dtEnd,
      submitForm,
      dateStartSelected,
      isLoading,
      scrollPageTo,
      personalDataElement,
      reset,
      lessThanFourHours,
    }
  },
}
</script>
